import React, { useState, useEffect } from "react";
import axios from "axios";
import {XMarkIcon, PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {PostComment} from "../../types/Post";
import LoadingScreen from "../LoadingScreen";
import {useAuth} from "../../auth/AuthContext";
import {toast} from "react-toastify";


interface PostDetailsModalProps {
    postId: number;
    onClose: () => void;
    authToken: string;
}

const PostDetailsModal: React.FC<PostDetailsModalProps> = ({
                                                               postId,
                                                               onClose,
                                                               authToken,
                                                           }) => {
    const [post, setPost] = useState<any>(null);
    const [comments, setComments] = useState<PostComment[]>([]);
    const [newComment, setNewComment] = useState("");
    const [loading, setLoading] = useState(false);
    const {user} = useAuth();
    const currentUserId = user?.id;

    useEffect(() => {
        fetchPostDetails();
    }, [postId]);

    const fetchPostDetails = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/api/forum/posts/${postId}`,
                { headers: { Authorization: authToken } }
            );
            setPost(response.data);
            setComments(response.data.comments || []);
        } catch (e) {
            console.error("Error fetching post details", e);
            toast.error('Error fetching post')
        }
    };

    const handleCommentSubmit = async () => {
        if (!newComment.trim()) return;
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/forum/comments`,
                { post_id: postId, content: newComment },
                { headers: { Authorization: authToken } }
            );
            setNewComment("");
            await fetchPostDetails();
            toast.success('Successfully submitted comment')
        } catch (e) {
            console.error("Error submitting comment", e);
            toast.error('Error submitting comment')
        }
        setLoading(false);
    };

    const handleCommentEdit = async (commentId: number, content: string) => {
        setLoading(true);
        try {
            await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/api/forum/comments/${commentId}`,
                { content },
                { headers: { Authorization: authToken } }
            );
            await fetchPostDetails();
        } catch (e) {
            console.error("Error editing comment", e);

            toast.error('Error editing comment')
        }
        setLoading(false);
    };


    const handleCommentDelete = async (commentId: number) => {
        setLoading(true)
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_BASE_URL}/api/forum/comments/${commentId}`,
                { headers: { Authorization: authToken } }
            );
            await fetchPostDetails();
            toast.success('Comment deleted')
        } catch (e) {
            console.error("Error deleting comment", e);
            toast.error('Error deleting comment')
        }
        setLoading(false)
    };
    // Close modal when clicking the backdrop
    const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!post) return <LoadingScreen />;

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 " onClick={handleBackdropClick}>
            {loading && <LoadingScreen />}
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full relative flex flex-col h-[70vh]" onClick={(e) => e.stopPropagation()}>
                {/* Close Button */}
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    <XMarkIcon className="w-6 h-6" />
                </button>
                <div className="overflow-auto flex-grow mb-2">
                {/* Post Title and Content */}
                <h2 className="text-2xl font-semibold mb-2">{post.title}</h2>
                <p className="text-gray-600 mb-4">{post.content}</p>

                {/* Post Info */}
                <div className="text-sm text-gray-500 mb-4">
                    <p>By: {post.user_name}</p>
                    <p>
                        Created: {new Date(post.created_at).toLocaleDateString()}
                        {" • "}
                        Likes: {post.likes_count || 0}
                    </p>
                </div>
                    <hr />
                {/* Comments Section */}
                <h3 className="text-lg font-semibold mb-2 mt-2">Comments</h3>
                {comments.length === 0 ? (
                    <p>No comments have been made on this post.</p>
                ) : (
                    <div className="space-y-4 mb-4">
                        {comments.map((comment) => (
                            <div
                                key={comment.id}
                                className={`flex justify-between p-2 border rounded ${
                                    comment.user_id === currentUserId
                                        ? "bg-blue-50 border-blue-300"
                                        : "border-gray-200"
                                }`}
                            >
                                <div>
                                <p className="text-gray-700">{comment.content}</p>
                                <div className="text-sm text-gray-500 flex">
                                    <span className="text-xs">By: {comment.user_name}</span>
                                </div>
                                </div>
                                {comment.user_id === currentUserId && (
                                    <div className="inline-grid gap-2 text-right">
                                        <button
                                            onClick={() =>
                                                handleCommentEdit(
                                                    comment.id,
                                                    prompt("Edit your comment:", comment.content) || comment.content
                                                )
                                            }
                                            className="text-blue-500 hover:underline text-xs"
                                        >
                                            <PencilSquareIcon className="w-5 h-5 inline mr-1" />
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleCommentDelete(comment.id)}
                                            className="text-red-500 hover:underline text-xs"
                                        >
                                            <TrashIcon className="w-5 h-5 inline mr-1" />
                                            Delete
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                </div>
                <hr />
                {/* New Comment Input */}
                <div className="p-4 bg-white flex gap-2">
                    <input
                        type="text"
                        placeholder="Write a comment..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        className="flex-grow border p-2 rounded-lg"
                    />
                    <button
                        onClick={handleCommentSubmit}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PostDetailsModal;
