import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {
    HomeIcon,
    ChartBarIcon,
    CogIcon,
    BriefcaseIcon,
    LanguageIcon,
    AdjustmentsHorizontalIcon,
    ArrowLeftEndOnRectangleIcon,
    ChatBubbleOvalLeftIcon,
    DocumentTextIcon,
    ChevronDoubleRightIcon, ChevronDoubleLeftIcon,
} from '@heroicons/react/16/solid';
import {useI18n} from '../../i18n/I18nContext';
import LanguageToggle from "../Buttons/LanguageToggle";
import {useAuth} from "../../auth/AuthContext";

const Sidebar: React.FC = () => {
    const {translations} = useI18n();
    const {signOut, user} = useAuth();
    const [confirmSignOut, setConfirmSignOut] = useState<boolean>(false);
    const isAdmin = user!.permission === "admin";
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };
    const handleSignOutClick = () => {
        if (!confirmSignOut) {
            setConfirmSignOut(true);
        } else {
            signOut();
        }
    };

    const handleCancelClick = () => {
        setConfirmSignOut(false);
    };
    return (
        <div
            className={`bg-white transition-width duration-300 ease-in-out ${isCollapsed ? 'w-20' : 'w-64'}`}>
            {!isCollapsed && (
                <div className={`bg-white flex flex-col justify-between h-full`}>
                    <div>
                        <div className="p-6 flex items-center">
                            <a className="w-full" href={'/'}>
                                <div className="w-full flex">
                                    <img src='/assets/images/arcular_logo.png' alt="Arcular Logo"
                                         className="mx-auto h-16 w-16"/>
                                    <img src='/assets/images/superu_logo.png' alt="Super U Logo"
                                         className="mx-auto h-16 w-16"/>
                                </div>
                            </a>
                        </div>
                        <hr/>
                        <nav className="mt-4">
                            <ul>
                                <li className="mb-2">
                                    <NavLink
                                        to="/"
                                        className={({isActive}) =>
                                            isActive
                                                ? "flex items-center p-2 text-white bg-primary rounded pl-6"
                                                : "flex items-center p-2 text-gray-600 hover:bg-gray-200 rounded pl-6"
                                        }
                                    >
                                        <HomeIcon className="w-6 h-6 mr-3"/>
                                        {translations.sidebar.home}
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink
                                        to="/jobs"
                                        className={({isActive}) =>
                                            isActive
                                                ? "flex items-center p-2 text-white bg-primary rounded pl-6"
                                                : "flex items-center p-2 text-gray-600 hover:bg-gray-200 rounded pl-6"
                                        }
                                    >
                                        <BriefcaseIcon className="w-6 h-6 mr-3"/>
                                        {translations.sidebar.jobs}
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink
                                        to="/translation-assistant"
                                        className={({isActive}) =>
                                            isActive
                                                ? "flex items-center p-2 text-white bg-primary rounded pl-6"
                                                : "flex items-center p-2 text-gray-600 hover:bg-gray-200 rounded pl-6"
                                        }
                                    >
                                        <LanguageIcon className="w-6 h-6 mr-3"/>
                                        {translations.sidebar.translationAssistant}
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink
                                        to="/pdf-editor"
                                        className={({isActive}) =>
                                            isActive
                                                ? "flex items-center p-2 text-white bg-primary rounded pl-6"
                                                : "flex items-center p-2 text-gray-600 hover:bg-gray-200 rounded pl-6"
                                        }
                                    >
                                        <DocumentTextIcon className="w-6 h-6 mr-3"/>
                                        {translations.sidebar.pdfEditor}
                                    </NavLink>
                                </li>
                                {isAdmin && <li className="mb-2">
                                    <NavLink
                                        to="/improve-the-ai"
                                        className={({isActive}) =>
                                            isActive
                                                ? "flex items-center p-2 text-white bg-primary rounded pl-6"
                                                : "flex items-center p-2 text-gray-600 hover:bg-gray-200 rounded pl-6"
                                        }
                                    >
                                        <AdjustmentsHorizontalIcon className="w-6 h-6 mr-3"/>
                                        {translations.sidebar.improveTheAi}
                                    </NavLink>
                                </li>
                                }
                                <li className="mb-2">
                                    <NavLink
                                        to="/feedback-forum"
                                        className={({isActive}) =>
                                            isActive
                                                ? "flex items-center p-2 text-white bg-primary rounded pl-6"
                                                : "flex items-center p-2 text-gray-600 hover:bg-gray-200 rounded pl-6"
                                        }
                                    >
                                        <ChatBubbleOvalLeftIcon className="w-6 h-6 mr-3"/>
                                        {translations.sidebar.feedbackForum}
                                    </NavLink>
                                </li>
                                <li className="mb-2">
                                    <NavLink
                                        to="/settings"
                                        className={({isActive}) =>
                                            isActive
                                                ? "flex items-center p-2 text-white bg-primary rounded pl-6"
                                                : "flex items-center p-2 text-gray-600 hover:bg-gray-200 rounded pl-6"
                                        }
                                    >
                                        <CogIcon className="w-6 h-6 mr-3"/>
                                        {translations.sidebar.settings}
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="p-6">
                        <LanguageToggle/>
                        <div className="mt-4 mb-8">
                            {confirmSignOut ? (
                                <div className="flex flex-col">
                                    <button
                                        onClick={handleSignOutClick}
                                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-transform transform hover:scale-105 mb-2"
                                    >
                                        {translations.sidebar.confirmSignOut}
                                    </button>
                                    <button
                                        onClick={handleCancelClick}
                                        className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-transform transform hover:scale-105"
                                    >
                                        {translations.sidebar.cancel}
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={handleSignOutClick}
                                    className="flex items-center text-gray-600 hover:bg-gray-200 rounded p-2 pl-6 w-full"
                                >
                                    <ArrowLeftEndOnRectangleIcon className="w-6 h-6 mr-3"/>
                                    {translations.sidebar.signOut}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <button
                className='absolute bottom-8 left-full transform -translate-x-full'
                onClick={toggleSidebar}
            >
                {isCollapsed ? (
                    <ChevronDoubleRightIcon className='h-8 w-20' />
                ) : (
                    <ChevronDoubleLeftIcon className='h-8 w-16' />
                )}
            </button>
        </div>
    );
};

export default Sidebar;
