import {
    Template,
    Font,
    checkTemplate,
    getInputFromTemplate,
    getDefaultFont,
    DEFAULT_FONT_NAME,
} from '@pdfme/common';
import { Form, Viewer, Designer } from '@pdfme/ui';
import { generate } from '@pdfme/generator';
import {
    text,
    image,
    line,
    table,
    rectangle,
    ellipse,
} from '@pdfme/schemas';
import {JobTemplateSchema} from "../../../types/Job";
const fontObjList = [
    {
        fallback: false,
        label: 'Helvetica',
        url: `${process.env.REACT_APP_PUBLIC_URL}/assets/fonts/Helvetica.ttf`,
    },
    {
        fallback: true,
        label: DEFAULT_FONT_NAME,
        data: getDefaultFont()[DEFAULT_FONT_NAME].data,
    },
    {
        fallback:false,
        label: 'NotoSansCN',
        url:`${process.env.REACT_APP_PUBLIC_URL}/assets/fonts/NotoSansCN.ttf`
    }
];

export const getFontsData = async () => {
    const fontDataList = (await Promise.all(
        fontObjList.map(async (font) => ({
            ...font,
            data: font.data || (await fetch(font.url || '').then((res) => res.arrayBuffer())),
        }))
    )) as { fallback: boolean; label: string; data: ArrayBuffer }[];

    return fontDataList.reduce((acc, font) => ({ ...acc, [font.label]: font }), {} as Font);
};

export const readFile = (file: File | null, type: 'text' | 'dataURL' | 'arrayBuffer') => {
    return new Promise<string | ArrayBuffer>((r) => {
        const fileReader = new FileReader();
        fileReader.addEventListener('load', (e) => {
            if (e && e.target && e.target.result && file !== null) {
                r(e.target.result);
            }
        });
        if (file !== null) {
            try {
                if (type === 'text') {
                    fileReader.readAsText(file);
                } else if (type === 'dataURL') {
                    fileReader.readAsDataURL(file);
                } else if (type === 'arrayBuffer') {
                    fileReader.readAsArrayBuffer(file);
                }
            }catch (e){
                console.error('There was an error reading the file')
            }
        }
    });
};



export const handleLoadTemplate = (
    currentRef: Designer | Form | Viewer | null,
    jobId: string,
    loadingTemplate: JobTemplateSchema[][]
) => {
        if(loadingTemplate){
            try {
                if (!currentRef) return;
                const currentPdf = currentRef.getTemplate().basePdf
                const currentVersion = currentRef.getTemplate().pdfmeVersion
                const template = {
                    "schemas": loadingTemplate,
                    "basePdf": currentPdf,
                    "pdfmeVersion": currentVersion
                }
                checkTemplate(template)
                currentRef.updateTemplate(template)
            } catch (e){
                if (!currentRef) return;
                console.error('An error was found when loading a template: ' + e)
                alert('Hmm... We ran into an error loading the template. Please try again')
            }
        } else {
            console.log('No template to load')
        }
};

export const getPlugins = () => {
    text.icon = `<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="24"
  height="24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
>
  <!-- Input-like box -->
  <rect x="2" y="6" width="20" height="12" rx="2" />
  
  <!-- Stylized T inside the box -->
  <path d="M9 10V8h6v2M12 10v6" />
</svg>`
    table.icon = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" /> </svg>'
    return {
        Text: text,
        Table: table,
        Line: line,
        Rectangle: rectangle,
        Ellipse: ellipse,
        Image: image
    };
};



export const generatePDF = async (currentRef: Designer | Form | Viewer | null, pdfName?: string) => {
    if (!currentRef) return;
    const template = currentRef.getTemplate();
    const options = currentRef.getOptions();
    const inputs =
        typeof (currentRef as Viewer | Form).getInputs === 'function'
            ? (currentRef as Viewer | Form).getInputs()
            : getInputFromTemplate(template);
    const font = await getFontsData();

    try {
        const pdf = await generate({
            template,
            inputs,
            options: {
                font,
                lang: options.lang,
                title: pdfName ? pdfName : 'pdf',
            },
            plugins: getPlugins(),
        });

        return new Blob([pdf.buffer], { type: 'application/pdf' });
    } catch (e) {
        alert(e + '\n\nCheck the console for full stack trace');
        throw e;
    }
};

export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

const getBlankTemplate = () =>
    ({
        schemas: [{}],
        basePdf: {
            width: 210,
            height: 297,
            padding: [20, 10, 20, 10],
        },
    } as Template);
export const getTemplatePresets = (): {
    key: string;
    label: string;
    template: () => Template;
}[] => [
    { key: 'custom', label: 'Custom', template: getBlankTemplate },
];

export const getTemplateByPreset = (templatePreset: string): Template => {
    const templatePresets = getTemplatePresets();
    const preset = templatePresets.find((preset) => preset.key === templatePreset);
    return preset ? preset.template() : templatePresets[0].template();
};