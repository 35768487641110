import React, {useEffect, useRef, useState} from "react";
import {cloneDeep, Template, Lang} from "@pdfme/common";
import {Designer} from "@pdfme/ui";
import {
    getFontsData,
    getPlugins,
    handleLoadTemplate,
    generatePDF,
} from "./helper";
import {toast, ToastContainer} from "react-toastify";
import LoadingScreen from "../../../components/LoadingScreen";
import {useNavigate, useParams} from "react-router-dom";
import {Job, JobTemplateSchema} from "../../../types/Job";
import axios from "axios";
import {config} from "../../../config";
import {useAuth} from "../../../auth/AuthContext";
import {useI18n} from "../../../i18n/I18nContext";
const headerHeight = 80;

function App() {
    const {jobId} = useParams<{ jobId: string }>();
    const { language, translations } = useI18n();
    const { user } = useAuth();
    const [job, setJob] = useState<Job | null>(null);
    const navigate = useNavigate();
    const [templateSchema, setTemplateSchema] = useState<JobTemplateSchema[][]>([]);
    const [originalSchema, setOriginalTemplateSchema] = useState<JobTemplateSchema[][]>([]);
    const designerRef = useRef<HTMLDivElement | null>(null);
    const designer = useRef<Designer | null>(null);
    const [lang, setLang] = useState<Lang>(language);
    const [loading, setLoading] = useState<boolean>(false);

    const pdfTranslations: { label: string; value: string }[] = [
        { value: 'en', label: translations.pdfEditor.languageNameOptions.languageNameEnglish },
        { value: 'zh', label: translations.pdfEditor.languageNameOptions.languageNameChinese }
    ];
    const buildDesigner = (originalTemplateSchema: JobTemplateSchema[][]) => {
        const template: Template = {
            schemas: originalTemplateSchema,
            basePdf: {
                width: 1000,
                height: 500,
                padding: [20, 10, 20, 10],
            },
            pdfmeVersion: '5.2.0'
        };

        getFontsData().then((font) => {
            if (designerRef.current) {
                // Destroy old instance if any
                if (designer.current) {
                    designer.current.destroy();
                    designer.current = null;
                }

                designer.current = new Designer({
                    domContainer: designerRef.current,
                    template,
                    options: {
                        font,
                        lang,
                        labels: {
                            clear: "🗑️", // example
                        },
                        theme: {
                            token: {
                                colorPrimary: "#25c2a0",
                            },
                        },
                        icons: {
                            multiVariableText:
                                '<svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6.643,13.072,17.414,2.3a1.027,1.027,0,0,1,1.452,0L20.7,4.134a1.027,1.027,0,0,1,0,1.452L9.928,16.357,5,18ZM21,20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"/></svg>',
                        },
                    },
                    plugins: getPlugins(),
                });
                // Attach events
                designer.current.onSaveTemplate(onSaveTemplate);
            }
        });
    };

    /**
     * Fetch the job details, then also fetch the job PDF so we can update the template basePdf.
     */
    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/api/job/get-job/${jobId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        },
                    }
                );

                if (response.status === 200) {
                    const jobData: Job = await response.data.job;
                    setJob(jobData);
                    if(jobData.pdf_original_template_schema){
                        setOriginalTemplateSchema(jobData.pdf_original_template_schema)
                    }
                    if (jobData.pdf_saved_template_schema) {
                        setTemplateSchema(jobData.pdf_saved_template_schema);
                        buildDesigner(jobData.pdf_saved_template_schema);
                    } else if(jobData.pdf_new_template_schema) {
                        setTemplateSchema(jobData.pdf_new_template_schema);
                        buildDesigner(jobData.pdf_new_template_schema);
                    } else if (jobData.pdf_original_template_schema) {
                        setTemplateSchema(jobData.pdf_original_template_schema);
                        buildDesigner(jobData.pdf_original_template_schema);
                    }else {
                        navigate('/jobs')
                    }

                    if (jobData.blank_pdf_key) {
                        await fetchJobPDF(jobData.blank_pdf_key);
                    }
                } else {
                    navigate("/jobs");
                }
            } catch (error) {
                console.error("Error fetching job details:", error);
                navigate("/jobs");
            } finally {
                setLoading(false);
            }
        };

        /**
         * Actually fetch the PDF from the job’s key/URL, then convert it
         * to a dataURL and update the Designer's basePdf
         */
        const fetchJobPDF = async (pdfKey: string) => {
            setLoading(true)
            try {
                // Example: your endpoint that returns raw PDF bytes from the job key
                const pdfResponse = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/api/job/download-pdf`,
                    {
                        params: { job_id: jobId, pdf_key: pdfKey },
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        },
                        responseType: "arraybuffer", // get raw bytes
                    }
                );

                if (pdfResponse.status === 200 && pdfResponse.data) {
                    // Convert array buffer -> base64 -> data:application/pdf URL
                    const base64String = btoa(
                        new Uint8Array(pdfResponse.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ""
                        )
                    );

                    const pdfDataUrl = "data:application/pdf;base64," + base64String;

                    // Then set the basePdf in the existing designer
                    if (designer.current) {
                        const curTemplate = designer.current.getTemplate();
                        designer.current.updateTemplate(
                            Object.assign(cloneDeep(curTemplate), {
                                basePdf: pdfDataUrl,
                            })
                        );
                    }
                } else {
                    console.error("Failed to download PDF from the job key.");
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchJobDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId, navigate]);


    const onSaveTemplate = async (template?: Template) => {
        if (!job?.job_name || !user?.id) {
            toast.error("Either job data or user data incomplete");
            return;
        }
        setLoading(true)
        const formData = new FormData();
        if (designer.current) {
            formData.append('job_id', job.id);
            formData.append('user_id', user.id);
            formData.append('new_template_schema', JSON.stringify(template?.schemas || designer.current.getTemplate().schemas));
        } else {
            toast.error("Error occured when parsing the schema data");
            return;
        }
        try {
            const response = await axios.post(`${config.API_BASE_URL}/api/job/save-schema`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.status === 200) {
                toast.success("Saved")
                setLoading(false);
            } else {
                setLoading(false);
                toast.error('An error occured when saving the schema');
            }
        } catch (error) {
            setLoading(false);
            toast.error('An error occurred when communicating with the database');
        }
    };

    const handleCompleteJob = async () => {
        if (!job?.job_name || !user?.id) {
            toast.error("Either job data or user data incomplete");
            return;
        }
        setLoading(true);

        const generatedPdf = await generatePDF(designer.current, job.pdf_name);
        const newSchema =  designer.current?.getTemplate().schemas;
        if (!generatedPdf || !newSchema){
            toast.error("There was an error generating the pdf");
            return;
        }
        const formData = new FormData();
        formData.append('pdf_file', generatedPdf);
        formData.append('job_id', job.id);
        formData.append('user_id', user.id);
        formData.append('new_template_schema', JSON.stringify(newSchema));
        try {
            const response = await axios.post(`${config.API_BASE_URL}/api/job/complete-from-schema`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.status === 200) {
                setLoading(false);
                window.open(URL.createObjectURL(generatedPdf));
                navigate("/jobs")
            } else {
                setLoading(false);
                toast.error('A database error occured generating pdf');
            }
        } catch (error) {
            setLoading(false);
            toast.error('An error occurred when communicating with the database');
        }


    };


    return (
        <div>
            <ToastContainer />
            {loading && <LoadingScreen />}
            <button
                onClick={() => navigate(-1)}
                className="text-primary flex items-center mb-4"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                </svg>
                {translations.buttons.backToJobsButton}
            </button>
            <header style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: '1rem',
                fontSize: 'small'
            }}>
                <label className="mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2" >
                    {translations.pdfEditor.language}{" "}
                    <select className="bg-blue-50" onChange={(e) => {
                        setLang(e.target.value as Lang)
                        if (designer.current) {
                            designer.current.updateOptions({lang: e.target.value as Lang})
                        }
                    }} value={lang}>
                        {pdfTranslations.map((t) => (
                            <option className="bg-blue-50" key={t.value} value={t.value}>{t.label}</option>
                        ))}
                    </select>
                </label>
                <button className="mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2" onClick={() => handleLoadTemplate(designer.current, jobId!, originalSchema)}>{translations.pdfEditor.reset}
                </button>
                <button className="mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2" onClick={() => onSaveTemplate()}>{translations.pdfEditor.save}</button>
                <button className="mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2" onClick={()=> handleCompleteJob()}>{translations.pdfEditor.generatePDF}</button>
            </header>
            <div ref={designerRef} style={{width: '100%', height: `calc(100vh - ${headerHeight}px)`}}/>
        </div>
    );
}

export default App;