import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from "../../components/LoadingScreen";
import { config } from "../../config";
import { useAuth } from "../../auth/AuthContext";
import { useI18n } from '../../i18n/I18nContext';

const JobInit: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { translations } = useI18n();
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [translationMethod, setTranslationMethod] = useState<'c-e' | 'e-c'>('c-e');
    const [textPlacement, setTextPlacement] = useState<'ontop' | 'replace'>('replace');
    const [jobName, setJobName] = useState('');
    const [loading, setLoading] = useState(false);
    const [fontSize, setFontSize] = useState<number>(10);
    const [editPdfMode, setEditPdfMode] = useState<'online' | 'offline'>('online');
    const [color, setColor] = useState<string>('(0, 0, 0)');
    const [hexColor, setHexColor] = useState<string>('#000000');

    const onDrop = (acceptedFiles: File[]) => {
        setPdfFile(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'application/pdf': ['.pdf'] }
    });
    // Convert hex to RGB
    const hexToRgb = (hex: string): string => {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : '(0, 0, 0)';
    };
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!pdfFile || !jobName || !user?.id) {
            toast.error(translations.jobInit.emptyFieldsMessage);
            return;
        }

        const formData = new FormData();
        formData.append('pdf_file', pdfFile);
        formData.append('translation_method', translationMethod);
        formData.append('text_placement', textPlacement);
        formData.append('job_name', jobName);
        formData.append('user_id', user.id);
        formData.append('font_size', fontSize.toString());
        formData.append('text_color', color);
        formData.append('edit_pdf_mode', editPdfMode);

        setLoading(true);

        try {
            const response = await axios.post(`${config.API_BASE_URL}/api/job/init`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.status === 200) {
                setLoading(false);
                navigate('/jobs', { state: { success: true } });
            } else {
                setLoading(false);
                toast.error(translations.jobInit.failedToInitiateJob);
            }
        } catch (error) {
            setLoading(false);
            toast.error(translations.jobInit.errorMessage);
        }
    };
    const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setHexColor(value);
        setColor(hexToRgb(value));  // Convert hex to RGB for backend submission
    };

    return (
        <div className="p-6 max-w-lg mx-auto">
            {loading && <LoadingScreen />}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

            {/* Back Button */}
            <button
                onClick={() => navigate('/jobs')}
                className="text-primary flex items-center mb-4"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                {translations.jobInit.backToJobs}
            </button>

            <h1 className="text-2xl font-bold mb-4">{translations.jobInit.title}</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">{translations.jobInit.jobNameLabel}</label>
                    <input
                        type="text"
                        value={jobName}
                        onChange={(e) => setJobName(e.target.value)}
                        placeholder={translations.jobInit.jobNamePlaceholder}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-1d2975"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">{translations.jobInit.translationMethodLabel}</label>
                    <select
                        value={translationMethod}
                        onChange={(e) => setTranslationMethod(e.target.value as 'c-e' | 'e-c')}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-1d2975"
                    >
                        <option value="c-e">{translations.jobInit.translationMethodOptions.ce}</option>
                        <option value="e-c">{translations.jobInit.translationMethodOptions.ec}</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">{translations.jobInit.textPlacementLabel}</label>
                    <select
                        value={textPlacement}
                        onChange={(e) => setTextPlacement(e.target.value as 'replace' | 'ontop')}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-1d2975"
                    >
                        <option value="replace">{translations.jobInit.textPlacementOptions.replace}</option>
                        <option value="ontop">{translations.jobInit.textPlacementOptions.ontop}</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">{translations.jobInit.editPdfModeLabel}</label>
                    <select
                        value={editPdfMode}
                        onChange={(e) => setEditPdfMode(e.target.value as 'online' | 'offline')}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-1d2975"
                    >
                        <option value="online">{translations.jobInit.editPdfMode.editPDFOnline}</option>
                        <option value="offline">{translations.jobInit.editPdfMode.editPDFOffline}</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">{translations.jobInit.colorPickerLabel}</label>
                    <input
                        type="color"
                        value={hexColor}
                        onChange={handleColorChange}
                        className="w-full p-4 border rounded focus:outline-none focus:ring-2 focus:ring-1d2975"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">{translations.jobInit.fontSizeLabel}</label>
                    <input
                        type="number"
                        value={fontSize}
                        onChange={(e) => setFontSize(parseFloat(e.target.value))}
                        min={8}
                        max={72}
                        step={0.5}
                        className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-1d2975"
                    />
                </div>
                <div
                    {...getRootProps()}
                    className={`p-6 border-2 border-dashed rounded-lg text-center cursor-pointer ${
                        isDragActive ? 'border-primary bg-blue-50' : 'border-gray-300'
                    }`}
                >
                    <input {...getInputProps()} />
                    {pdfFile ? (
                        <p>{translations.jobInit.fileUpload.fileSelected} {pdfFile.name}</p>
                    ) : (
                        <p>{translations.jobInit.fileUpload.instructions}</p>
                    )}
                </div>

                <button
                    type="submit"
                    className="mt-6 w-full p-3 bg-primary text-white rounded hover:bg-blue-600 transition-transform transform hover:scale-105"
                >
                    {translations.jobInit.submitButton}
                </button>
            </form>
        </div>
    );
};

export default JobInit;
