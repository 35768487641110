import React, { useEffect, useState } from 'react';
import { Job } from '../types/Job';
import { useNavigate } from 'react-router-dom';
import { useI18n } from '../i18n/I18nContext';
import axios from "axios";
import {toast} from "react-toastify";
import LoadingScreen from "./LoadingScreen";
import LoaderBar from "./LoadingBar/LoadingBar";

interface JobCardProps {
    job: Job;
    onDelete?: (jobId: string) => void;
}

const JobCard: React.FC<JobCardProps> = ({ job, onDelete }) => {
    const navigate = useNavigate();
    const [timeElapsed, setTimeElapsed] = useState<number>(0);
    const { translations } = useI18n();
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        if (job.status === 'initiated') {
            const createdAt = new Date(job.created_at).getTime();
            const updateElapsedTime = () => {
                const currentTime = Date.now();
                setTimeElapsed(Math.floor((currentTime - createdAt) / 1000)); // seconds
            };

            updateElapsedTime();
            const interval = setInterval(updateElapsedTime, 1000);

            return () => clearInterval(interval);
        } else if (job.status === 'checkpoint_two' && job.checkpoint_two_completed_at){
            const checkpointTwoAt = new Date(job.checkpoint_two_completed_at).getTime();
            const updateElapsedTime = () => {
                const currentTime = Date.now();
                setTimeElapsed(Math.floor((currentTime - checkpointTwoAt) / 1000)); // seconds
            };

            updateElapsedTime();
            const interval = setInterval(updateElapsedTime, 1000);

            return () => clearInterval(interval);
        }
    }, [job.status, job.created_at]);

    const formatTime = (seconds: number) => {
        const hrs = Math.floor((seconds % 3600) / 60 / 60);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const handleAuditClick = () => {
        navigate(`/jobs/audit/${job.id}`); // Navigate to the audit page with the jobId
    };
    const handleEditPdfClick = () => {
        navigate(`/jobs/edit-pdf/${job.id}`); // Navigate to the audit page with the jobId
    };
    const handleDeleteClick = async () => {
        if (deleting) return;
        const confirmDelete = window.confirm(translations.jobs.jobCard.deleteConfirmation);
        if (!confirmDelete) return;
        setDeleting(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/job/delete-job/${job.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.status === 200) {
                toast.success(translations.jobs.jobCard.deleteSuccess);
                if (onDelete) {
                    onDelete(job.id); // Remove the job from the UI if the callback is provided
                }
            } else {
                toast.error(translations.jobs.jobCard.deleteError);
            }
        } catch (error) {
            toast.error(translations.jobs.jobCard.deleteError);
        } finally {
            setDeleting(false);
        }
    };

    const getStatusTranslation = (status: string) => {
        switch (status) {
            case 'initiated':
                return translations.jobs.jobCard.statusValues.initiated;
            case 'checkpoint_one':
                return translations.jobs.jobCard.statusValues.checkpoint_one;
            case 'checkpoint_two':
                return translations.jobs.jobCard.statusValues.checkpoint_two;
            case 'complete':
                return translations.jobs.jobCard.statusValues.complete;
            default:
                return status;
        }
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-4 mb-4 flex justify-between items-center">
            <div>
                <h3 className="text-lg font-semibold">{translations.jobs.jobCard.projectName} {job.job_name}</h3>
                <span className="text-sm text-gray-600">{translations.jobs.jobCard.status} {getStatusTranslation(job.status)}</span>
                {(job.status === 'initiated' && !job.in_queue) && (
                    <div className="mt-2">
                        <div className="flex items-center">
                            <span className="text-sm text-gray-500">Translating</span>
                        </div>
                        <LoaderBar />
                    </div>
                )}
                {(job.status === 'checkpoint_one' && !job.in_queue  && job.edit_pdf_mode === "online") && (
                    <div className="mt-2">
                        <div className="flex items-center">
                            <span className="text-sm text-gray-500">Generating PDF</span>
                        </div>
                        <LoaderBar />
                    </div>
                )}
                {(job.in_queue && (job.status !== 'complete' && job.status !== "error")) && (
                    <div className="mt-2">
                        <div className="flex items-center">
                            <span className="text-sm text-gray-500">In Queue</span>
                        </div>
                    </div>
                )}
                {(job.status === 'checkpoint_two' && job.edit_pdf_mode === "offline") && (
                    <div className="mt-2">
                        <div className="flex items-center">
                            <span className="text-sm text-gray-500">{translations.jobs.jobCard.timeElapsed} {formatTime(timeElapsed)}</span>
                        </div>
                        <LoaderBar />
                    </div>
                )}
            </div>
            <div className="flex items-center">
                <button
                    onClick={handleDeleteClick}
                    className="bg-red-500 text-white px-3 py-1 h-fit rounded hover:bg-red-600 mx-4 whitespace-nowrap text-nowrap"
                    disabled={deleting}
                >
                    {deleting ? translations.jobs.jobCard.deleting : translations.jobs.jobCard.delete}
                </button>
                {deleting && <LoadingScreen />}
                {(job.status === 'checkpoint_one'  && job.edit_pdf_mode === "offline") && (
                    <div className="w-full grid">
                        <span className="text-sm text-gray-500 block mb-2">{translations.jobs.jobCard.translationComplete}</span>
                        <button
                            onClick={handleAuditClick}
                            className="mx-auto w-fit bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
                        >
                            {translations.jobs.jobCard.auditTranslations}
                        </button>
                    </div>
                )}
                {(job.status === 'checkpoint_two' && job.edit_pdf_mode === "online") && (
                    <div className="w-full grid">
                        <button
                            onClick={handleEditPdfClick}
                            className="mx-auto w-fit bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
                        >
                            {translations.jobs.jobCard.editPdf}
                        </button>
                    </div>
                )}
                {job.status === 'complete' && (
                    <button
                        onClick={() => navigate(`/jobs/view/${job.id}`)}
                        className="bg-primaryYellow text-primary px-3 py-1 rounded hover:bg-yellow-400"
                    >
                        {translations.jobs.jobCard.viewJob}
                    </button>
                )}
                {job.status === 'error' && (
                    <button
                        onClick={() => navigate(`/jobs/view/${job.id}`)}
                        className="bg-primaryYellow text-primary px-3 py-1 rounded hover:bg-yellow-400"
                    >
                        {translations.jobs.jobCard.viewJob}
                    </button>
                )}
            </div>
        </div>
    );
};

export default JobCard;
