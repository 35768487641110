import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useI18n} from '../../i18n/I18nContext';
import JobGeneratingLoadingScreen from './JobGeneratingLoadingScreen';
import {Job} from "../../types/Job";
import './timeline.css';
import ViewJobSkeleton from "../../components/Skeletons/ViewJobSkeleton";

const ViewJob: React.FC = () => {
    const {jobId} = useParams<{ jobId: string }>();
    const {translations} = useI18n();
    const navigate = useNavigate();

    const [job, setJob] = useState<Job | null>(null);
    const [translationPairs, setTranslationPairs] = useState<{ original: string; translated: string; }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [downloading, setDownloading] = useState<boolean>(false);

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/job/get-job/${jobId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (response.status === 200) {
                    setJob(response.data.job);
                    setTranslationPairs(response.data.job.translation_pairs);
                } else {
                    navigate('/jobs'); // Redirect to jobs if the job is not found
                }
            } catch (error) {
                console.error('Error fetching job details:', error);
                navigate('/jobs'); // Redirect to jobs if there's an error
            } finally {
                setLoading(false);
            }
        };

        fetchJobDetails();
    }, [jobId, navigate]);

    const downloadPdf = async (pdfKey: string) => {
        setDownloading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/job/download-pdf`, {
                params: {
                    job_id: jobId,
                    pdf_key: pdfKey,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
                responseType: 'blob',
            });

            if (response.status === 200) {
                const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
                const pdfUrl = window.URL.createObjectURL(pdfBlob);
                // Create a temporary anchor element and trigger the download
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `${job?.pdf_name}`;
                document.body.appendChild(link);
                link.click();

                // Clean up after the download
                document.body.removeChild(link);
                window.URL.revokeObjectURL(pdfUrl);
            } else {
                console.error('Failed to download PDF.');
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        } finally {
            setDownloading(false);
        }
    };

    const calculateDuration = (start: string, end: string) => {
        const startTime = new Date(start).getTime();
        const endTime = new Date(end).getTime();
        const duration = Math.floor((endTime - startTime) / 1000); // in seconds
        const hrs = Math.floor(duration / 3600);
        const mins = Math.floor((duration % 3600) / 60);
        const secs = duration % 60;
        return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    if (loading) {
        return <ViewJobSkeleton/>;
    }

    if (!job) {
        return <div>Job not found or an error occurred.</div>; // Fallback content if job is not found
    }

    // Ensure that the status translation exists, otherwise fallback to the raw status
    const jobStatusTranslation = translations.jobs.jobCard.statusValues[job.status as keyof typeof translations.jobs.jobCard.statusValues] || job.status;

    return (
        <div className="p-6 max-w-4xl mx-auto">
            {/* Back Button */}
            <button
                onClick={() => navigate(-1)}
                className="text-primary flex items-center mb-4"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"/>
                </svg>
                {translations.buttons.backToJobsButton}
            </button>
            <h1 className="text-2xl font-bold mb-4">{job.job_name}</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-xl font-semibold">{translations.viewJob.status}</h2>
                    <p className="text-gray-700">{jobStatusTranslation.toString()}</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-xl font-semibold">{translations.viewJob.accuracy}</h2>
                    <div className="">
                        <div className="flex">
                            <h4 className="text-sm font-semibold">{translations.viewJob.aiAccuracy}: </h4>
                            <p className="text-gray-700 text-sm ml-2">{(job.ai_accuracy || job.ai_accuracy === 0) ? (job.ai_accuracy).toFixed(2) + '%' : 'Coming Soon'}</p>
                        </div>
                        <div className="flex">
                            <h4 className="text-sm font-semibold">{translations.viewJob.extractionAccuracy}: </h4>
                            <p className="text-gray-700 text-sm ml-2">{job.extraction_accuracy ? (job.extraction_accuracy).toFixed(2) + '%' : 'Coming Soon'}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
                <h2 className="text-xl font-bold mb-2">{translations.viewJob.timeline}</h2>
                <div className="timeline-container">
                    <div className="timeline-horizontal">
                        <div className="timeline-event">
                            <div className="timeline-event-marker"></div>
                            <div className="timeline-event-content">
                                <strong>{translations.viewJob.createdAt}</strong>
                                <p>{new Date(job.created_at).toLocaleString()}</p>
                            </div>
                        </div>
                        {job.checkpoint_one_completed_at && (
                            <div className="timeline-event">
                                <div className="timeline-event-marker"></div>
                                <div className="timeline-event-content">
                                    <strong>{translations.viewJob.checkpointOne}</strong>
                                    <p>{new Date(job.checkpoint_one_completed_at).toLocaleString()}</p>
                                </div>
                            </div>
                        )}
                        {job.checkpoint_two_completed_at && (
                            <div className="timeline-event">
                                <div className="timeline-event-marker"></div>
                                <div className="timeline-event-content">
                                    <strong>{translations.viewJob.checkpointTwo}</strong>
                                    <p>{new Date(job.checkpoint_two_completed_at).toLocaleString()}</p>
                                </div>
                            </div>
                        )}
                        {job.status === 'complete' && job.completed_at && (
                            <div className="timeline-event">
                                <div className="timeline-event-marker"></div>
                                <div className="timeline-event-content">
                                    <strong>{translations.viewJob.completedAt}</strong>
                                    <p>{new Date(job.completed_at).toLocaleString()}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    {job.status === 'complete' && job.completed_at && (
                        <div className="mt-4 text-gray-700">
                            <strong>{translations.viewJob.totalTime}:</strong> {calculateDuration(job.created_at, job.completed_at)}
                        </div>
                    )}
                </div>
            </div>

            <h2 className="text-xl font-bold mb-2">{translations.viewJob.downloads}</h2>
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <button
                        onClick={() => downloadPdf(job.original_pdf_key)}
                        className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-950 transition-transform transform hover:scale-105"
                        disabled={downloading}
                    >
                        {translations.viewJob.downloadOriginal}
                    </button>
                    {job.updated_pdf_key && (
                        <button
                            onClick={() => downloadPdf(job.updated_pdf_key)}
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-transform transform hover:scale-105"
                            disabled={downloading}
                        >
                            {translations.viewJob.downloadTranslated}
                        </button>
                    )}
                    {job.edit_pdf_mode === "online" && (
                        <button
                            onClick={() => navigate(`/jobs/edit-pdf/${job?.id}`)}
                            className="bg-blue-50 border-primary border-2 text-primary px-4 py-2 rounded hover:bg-blue-100 transition-transform transform hover:scale-105"

                        >
                            Edit PDF
                        </button>
                    )}
                </div>
            </div>

            <h2 className="text-xl font-bold mb-2">{translations.viewJob.translationPairs}</h2>
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
                <h3 className="capitalize font-semibold mb-4">{translations.viewJob.totalTranslatedPairs}: {job.text_chunks_translated ? job.text_chunks_translated : translationPairs.length}</h3>
                {translationPairs.map((pair, index) => (
                    <div key={index} className="mb-4">
                        <div className="grid grid-cols-2 rounded-lg ">
                            <div className="bg-gray-100 p-3 border-r-2 border-r-gray-500">
                                <p className="font-bold  text-sm">{translations.viewJob.originalText}</p>
                                <p className="text-gray-700">{pair.original}</p>
                            </div>
                            <div className="bg-gray-100 p-3 ">
                                <p className="font-bold text-sm">{translations.viewJob.translatedText}</p>
                                <p className="text-gray-700">{pair.translated}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {downloading && <JobGeneratingLoadingScreen message={translations.viewJob.downloading}/>}
        </div>
    );
};

export default ViewJob;
