import React from 'react';
import './LoadingBar.css';

const LoaderBar = () => {
    return (
        <div className="relative w-full max-w-md h-4 bg-primary rounded overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent shimmer"></div>
        </div>
    );
};

export default LoaderBar;