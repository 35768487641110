import React, { useEffect, useState } from "react";
import axios from "axios";
import {Post} from "../../types/Post";
import {HeartIcon, MagnifyingGlassIcon, TrashIcon} from "@heroicons/react/24/outline";
import {PlusCircleIcon} from "@heroicons/react/24/solid";
import LoadingScreen from "../../components/LoadingScreen";
import { toast, ToastContainer } from 'react-toastify';
import PostDetailsModal from "../../components/Modals/PostDetailModal";


const FeedbackForum: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
    const [newPost, setNewPost] = useState({ title: "", content: "" });
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const authToken = `Bearer ${localStorage.getItem('authToken')}`;
    // Fetch all posts
    const fetchPosts = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forum/posts`, {
                headers: {Authorization: authToken},
            });
            setPosts(response.data);
        }catch (e){
            toast.error('Error fetching posts')
            console.error(e)
        }
        setLoading(false)
    };

    // Search posts
    const searchPosts = async () => {
        try{
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forum/posts/search`, {
            headers: { Authorization: authToken },
            params: { keyword: searchTerm }
        });
        setPosts(response.data);
        }catch (e){
            toast.error('Error searching posts')
            console.error(e)
        }
    };

    // Create a post
    const createPost = async () => {
        setLoading(true)
        try{
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/forum/posts/create`, newPost, {
            headers: { Authorization: authToken },
        });
        setNewPost({ title: "", content: "" });
        setShowModal(false);
        await fetchPosts();
        }catch (e){
            toast.error('Error creating post')
            console.error(e)
        }
        setLoading(false)

    };

    // Delete a post
    const deletePost = async (id: number) => {
        setLoading(true)
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/forum/posts/${id}`, {
                headers: {Authorization: authToken},
            });
            await fetchPosts();
        }catch (e){
            toast.error('Error deleting post')
            console.error(e)
        }
        setLoading(false)
    };

    // Toggle Like/Unlike
    const toggleLikePost = async (post: Post) => {
        setLoading(true)
        try {
            if (post.liked_by_user) {
                // Unlike the post
                await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/forum/posts/${post.id}/like`, {
                    headers: {Authorization: authToken},
                });
            } else {
                // Like the post
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/forum/posts/${post.id}/like`, {}, {
                    headers: {Authorization: authToken},
                });
            }

            // Update the local state
            setPosts((prevPosts) =>
                prevPosts.map((p) =>
                    p.id === post.id ? {...p, liked_by_user: !post.liked_by_user} : p
                )
            );
        }catch (e){
        toast.error('Error liking/unliking post')
        console.error(e)
    }
    setLoading(false)
    };

    useEffect(() => {
        fetchPosts();
    }, []);


    return (
        <div className="max-w-4xl mx-auto p-4">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
            {loading && <LoadingScreen />}
            {/* Title */}
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">Feedback Forum</h1>
                <button
                    onClick={() => setShowModal(true)}
                    className="bg-primary text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-primaryDeepBlue"
                >
                    <PlusCircleIcon className="w-6 h-6 mr-3" />
                    Create Post
                </button>
            </div>

            {/* Search Bar */}
            <div className="flex items-center mb-6">
                <input
                    type="text"
                    placeholder="Search posts..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-l-lg"
                />
                <button
                    onClick={searchPosts}
                    className="bg-primary text-white p-2 rounded-r-lg hover:bg-primaryDeepBlue"
                >
                    <MagnifyingGlassIcon className="w-6 h-6 mr-3" />
                </button>
            </div>

            {/* Create Post */}
            {showModal && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
                        <h2 className="text-2xl font-semibold mb-4">Create a New Post</h2>
                        <input
                            type="text"
                            placeholder="Title"
                            value={newPost.title}
                            onChange={(e) =>
                                setNewPost({ ...newPost, title: e.target.value })
                            }
                            className="w-full p-2 mb-4 border border-gray-300 rounded"
                        />
                        <textarea
                            placeholder="Content"
                            value={newPost.content}
                            onChange={(e) =>
                                setNewPost({ ...newPost, content: e.target.value })
                            }
                            className="w-full p-2 mb-4 border border-gray-300 rounded"
                        ></textarea>
                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setShowModal(false)}
                                className="px-4 py-2 text-gray-500 border border-gray-300 rounded hover:bg-gray-100"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={createPost}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/* Posts List */}
            {posts && !loading && posts.length > 0 ?
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {posts.map((post) => (
                    <div
                        key={post.id}
                        className="p-4 border border-gray-200 rounded-lg shadow-md bg-white cursor-pointer hover:shadow-lg"
                        onClick={() => setSelectedPostId(post.id)}
                    >
                        <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                        <p className="text-gray-600 mb-4">{post.content.slice(0, 30)}</p>
                        <div className="flex justify-between items-center text-sm text-gray-500">
              <span>
                By {post.user_name}
              </span>
                            <div className="flex gap-2">

                                <button
                                    onClick={async(e) => {
                                        e.preventDefault()
                                        await toggleLikePost(post)
                                    }}
                                    className={`p-2 rounded-full`}
                                >
                                    <HeartIcon className={`w-6 h-6 ${
                                        post.liked_by_user && "text-red-600 fill-red-600" 
                                    }`}/>
                                </button>
                                <button
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await deletePost(post.id);
                                    }}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <TrashIcon className="w-6 h-6 mr-3"/>
                                </button>
                            </div>
                        </div>
                        {selectedPostId && (
                            <PostDetailsModal
                                postId={selectedPostId}
                                onClose={() => setSelectedPostId(null)}
                                authToken={authToken}
                            />
                        )}
                    </div>
                ))}
            </div>
                : <p className="text-center">No posts have been made yet</p>
            }
        </div>
    );
};

export default FeedbackForum;
