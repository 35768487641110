import {useEffect, useRef, useState} from "react";
import {cloneDeep, Template, checkTemplate, Lang} from "@pdfme/common";
import {Designer} from "@pdfme/ui";
import {
    getFontsData,
    getTemplatePresets,
    getTemplateByPreset,
    readFile,
    getPlugins,
    handleLoadTemplate,
    generatePDF,
    downloadJsonFile,
} from "./helper";
import LoadingScreen from "../../components/LoadingScreen";
import {toast, ToastContainer} from "react-toastify";
import {useI18n} from "../../i18n/I18nContext";

const headerHeight = 80;

const initialTemplatePresetKey = "custom"
const customTemplatePresetKey = "custom";

const templatePresets = getTemplatePresets();

function App() {
    const designerRef = useRef<HTMLDivElement | null>(null);
    const { language, translations } = useI18n();
    const designer = useRef<Designer | null>(null);
    const [lang, setLang] = useState<Lang>(language);
    const [templatePreset, setTemplatePreset] = useState<string>(localStorage.getItem("templatePreset") || initialTemplatePresetKey);
    const [prevDesignerDom, setPrevDesignerDom] = useState<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const pdfTranslations: { label: string; value: string }[] = [
        { value: 'en', label: translations.pdfEditor.languageNameOptions.languageNameEnglish },
        { value: 'zh', label: translations.pdfEditor.languageNameOptions.languageNameChinese }
    ];
    const buildDesigner = () => {
        setLoading(true)
        let template: Template = getTemplateByPreset(localStorage.getItem('templatePreset') || "");

        getFontsData().then((font) => {
            if (designerRef.current) {
                designer.current = new Designer({
                    domContainer: designerRef.current,
                    template,
                    options: {
                        font,
                        lang,
                        labels: {
                            'clear': '🗑️', // Add custom labels to consume them in your own plugins
                        },
                        theme: {
                            token: {
                                colorPrimary: '#25c2a0',
                            },
                        },
                        icons: {
                            multiVariableText: '<svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6.643,13.072,17.414,2.3a1.027,1.027,0,0,1,1.452,0L20.7,4.134a1.027,1.027,0,0,1,0,1.452L9.928,16.357,5,18ZM21,20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"/></svg>'
                        },
                    },
                    plugins: getPlugins(),
                });
                designer.current.onSaveTemplate(onSaveTemplate);
                designer.current.onChangeTemplate(() => {
                    setTemplatePreset(customTemplatePresetKey);
                })
            }
        });
        setLoading(false)
    }
    useEffect(() => {
        // If there's an existing instance, destroy it before rebuild
        if (designer.current) {
            designer.current.destroy();
            designer.current = null;
        }
        if (designerRef.current) {
            buildDesigner();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [designerRef.current, lang, templatePreset]);

    const onChangeBasePDF = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files) {
            setLoading(true)
            try {
                readFile(e.target.files[0], "dataURL").then(async (basePdf) => {
                    if (designer.current) {
                        designer.current.updateTemplate(
                            Object.assign(cloneDeep(designer.current.getTemplate()), {
                                basePdf,
                            })
                        );
                    }
                });
            } catch (e){
                console.error(e)
            }
            setLoading(false)
        }
    };

    const onDownloadTemplate = () => {
        if (designer.current) {
            downloadJsonFile(designer.current.getTemplate(), "template");
            console.log(designer.current.getTemplate());
        }
    };

    const onSaveTemplate = (template?: Template) => {
        setLoading(true)
        if (designer.current) {
            localStorage.setItem(
                "template",
                JSON.stringify(template?.schemas || designer.current.getTemplate().schemas)
            );
            toast.success("Saved")
        }
        setLoading(false)
    };

    const onChangeTemplatePresets = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTemplatePreset(e.target.value);
        localStorage.setItem("template", JSON.stringify(getTemplateByPreset(localStorage.getItem('templatePreset') || "")));
        localStorage.removeItem("template");
        localStorage.setItem("templatePreset", e.target.value);
        buildDesigner();
    }


    return (
        <div>
            <ToastContainer />
            {loading && <LoadingScreen />}
            <header style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: '1rem',
                fontSize: 'small'
            }}>
                <label className="mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2" >
                    {translations.pdfEditor.language}{" "}
                    <select className="bg-blue-50" onChange={(e) => {
                        setLang(e.target.value as Lang)
                        if (designer.current) {
                            designer.current.updateOptions({lang: e.target.value as Lang})
                        }
                    }} value={lang}>
                        {pdfTranslations.map((t) => (
                            <option className="bg-blue-50" key={t.value} value={t.value}>{t.label}</option>
                        ))}
                    </select>
                </label>
                <div className="flex flex-col mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2">
                    <label className="mx-2">
                        {translations.pdfEditor.uploadPDF}
                    </label>
                    <input className="mx-2 bg-blue-50  hover:bg-blue-100 cursor-pointer" type="file" accept="application/pdf" onChange={onChangeBasePDF}/>
                </div>
                <button className="mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2" onClick={() => handleLoadTemplate(designer.current)}>{translations.pdfEditor.loadWork}
                </button>
                <button className="mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2" onClick={() => onSaveTemplate()}>{translations.pdfEditor.save}</button>
                <button className="mx-2 bg-blue-50 rounded-lg border border-primary hover:bg-blue-100 cursor-pointer px-4 py-2" onClick={async () => {
                    setLoading(true)
                    await generatePDF(designer.current)
                    setLoading(false)
                }}>{translations.pdfEditor.generatePDF}</button>
            </header>
            <div ref={designerRef} style={{width: '100%', height: `calc(100vh - ${headerHeight}px)`}}/>
        </div>
    );
}

export default App;